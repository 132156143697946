var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-5 app_eng_written_assessment"},[(_vm.submit_ans_loading)?[_c('AppEngWrittenAssessmentLoader')]:(!_vm.app_eng_assessments.written)?[_c('ErrorPage')]:[_c('v-row',{staticClass:"d-flex align-start align-lg-center flex-column flex-lg-row",staticStyle:{"gap":"1rem"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('AppEngAssessmentHeader',{attrs:{"assessment_type":_vm.assessment_type}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('candidate.assessments.writting-instructions'))}})]),(!_vm.current_question)?_c('v-col',{staticClass:"text-left question-label",attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("candidate.assessments.select-topic-from")))])]):_vm._e(),(!_vm.current_question)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"8"}},[_c('v-select',{staticClass:"w-100",attrs:{"items":_vm.writting_questions,"item-text":"text","item-value":"value","label":"Select Question"},on:{"change":_vm.handleQuestionChange},model:{value:(_vm.selected_question),callback:function ($$v) {_vm.selected_question=$$v},expression:"selected_question"}})],1):[_c('v-progress-linear',{staticClass:"progress-bar",attrs:{"height":"20","rounded":"","color":"primary","background-color":"transparent","value":(_vm.answer_words_count /
              _vm.current_question.question_options.allowed_characters[0]) *
            100}}),_c('div',{staticClass:"time-chip"},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/linear/clock.svg')}}),_c('p',{staticClass:"base-body-text mb-0"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.convert_seconds_to_minutes( _vm.current_question.question_time_allowed ))+" "+_vm._s(_vm.$t("shared.minutes")))])])],1),_c('div',{staticClass:"time-chip"},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/linear/document-filter.svg')}}),_c('p',{staticClass:"base-body-text mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.current_question.question_options.allowed_characters[0])+" "),(
                _vm.current_question.question_options.allowed_characters.length >
                  1 &&
                _vm.current_question.question_options.allowed_characters[1] !==
                  _vm.current_question.question_options.allowed_characters[0]
              )?_c('span',[_vm._v(" - "+_vm._s(_vm.current_question.question_options.allowed_characters[1])+" ")]):_vm._e(),_vm._v(" Words ")])],1)],_c('div',[(_vm.current_question)?_c('AppTimer',{key:_vm.current_question_index,attrs:{"time_seconds":_vm.current_question.question_time_allowed},on:{"time_over":_vm.process_next_question}}):_vm._e()],1)],2),(_vm.current_question)?[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"writing-card mt-6"},[(
                _vm.current_question.question_type ===
                _vm.AppAssessments.QuestionType.HTML
              )?_c('p',{staticClass:"base-body-text text-dark--text",domProps:{"innerHTML":_vm._s(_vm.current_question.question_text)}}):_c('p',{staticClass:"base-body-text"},[_vm._v(" "+_vm._s(_vm.current_question.question_text)+" ")])])]),(
            _vm.current_question &&
            _vm.current_question.question_options &&
            _vm.current_question.question_options.question_material
          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
              _vm.current_question.question_options.table &&
              _vm.current_question.question_options.table.columns.length > 0
            )?_c('div',[_c('v-row',[_c('v-col',{staticClass:"table-card mb-2",attrs:{"cols":"12","md":"5"}}),_c('v-col',{class:("table-card mb-2 ml-2 " + (_vm.get_desired_color(0)) + "--text"),attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.current_question.question_options.table.columns[0])+" ")]),_c('v-col',{class:("table-card mb-2 ml-2 " + (_vm.get_desired_color(1)) + "--text"),attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.current_question.question_options.table.columns[1])+" ")]),_c('v-col',{class:("table-card mb-2 ml-2 " + (_vm.get_desired_color(2)) + "--text"),attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.current_question.question_options.table.columns[2])+" ")])],1),_vm._l((_vm.current_question.question_options.table
                .rows),function(data,index){return _c('v-row',{key:index},[_c('v-col',{class:("table-card mb-2 " + (_vm.get_desired_color(index)) + "--text"),attrs:{"cols":"12","md":"5"}},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('v-col',{staticClass:"table-card mb-2 ml-2",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(data.col1)+" ")]),_c('v-col',{staticClass:"table-card mb-2 ml-2",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(data.col2)+" ")]),_c('v-col',{staticClass:"table-card mb-2 ml-2",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(data.col3)+" ")])],1)})],2):_c('div',{staticClass:"question-material-col"},[_c('img',{attrs:{"src":_vm.current_question.question_options.question_material}})])]):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-10"},[_c('span',{on:{"paste":_vm.event_cancellation,"copy":_vm.event_cancellation,"cut":_vm.event_cancellation}},[_c('quill-editor',{ref:"answer_editor",staticClass:"answer-editor",attrs:{"options":_vm.answer_editor_options},model:{value:(_vm.current_question_answer),callback:function ($$v) {_vm.current_question_answer=$$v},expression:"current_question_answer"}})],1)]),_c('div',{staticClass:"mt-14 pt-14 pt-sm-0"},[_c('p',{staticClass:"base-body-text grey--text ml-2"},[_vm._v(" "+_vm._s(_vm.answer_words_count)+" /( "),(
                  _vm.current_question.question_options &&
                  _vm.current_question.question_options.allowed_characters &&
                  _vm.current_question.question_options.allowed_characters
                    .length > 0
                )?[_vm._v(" "+_vm._s(_vm.current_question.question_options.allowed_characters[0])+" "),(
                    _vm.current_question.question_options.allowed_characters
                      .length > 1 &&
                    _vm.current_question.question_options
                      .allowed_characters[1] !==
                      _vm.current_question.question_options.allowed_characters[0]
                  )?_c('span',[_vm._v(" - "+_vm._s(_vm.current_question.question_options.allowed_characters[1])+" ")]):_vm._e()]:_vm._e(),_vm._v(") "+_vm._s(_vm.$t("shared.words"))+" ")],2)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-end py-10 py-md-10"},[_c('v-btn',{staticClass:"next-btn",class:{
                'base-submit-button': !_vm.disabled_next_question,
                'base-disable-button': _vm.disabled_next_question
              },attrs:{"loading":_vm.submit_ans_loading,"color":"primary","disabled":_vm.disabled_next_question},on:{"click":_vm.process_next_question}},[_vm._v(" "+_vm._s(_vm.$t("shared.next"))+" ")])],1)])],1)]:_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }