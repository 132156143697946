













































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import AppEngAssessmentHeader from "./AppEngAssessmentHeader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppEngWrittenAssessment from "./AppEngWrittenAssessment.vue";
import AppEngV3WrittenAssessment from "./AppEngV3WrittenAssessment.vue";
import {
  ViewAssessmentsOptions,
  AppEnglish3McqsAnswers
} from "@/interfaces/candidate/canidate_assessments";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import {
  AssessmentChat,
  AssessmentChatRoles,
  SubmitAssessmentAnswersApiPayload,
  SubmitAssessmentMcqsAnswersApiPayload,
  UpdateAssessmentQuestionPayload,
  UpdateAssessmentMcqsPayload
} from "@/store/modules/candidates/interfaces";
import {
  APP_ASSESSMENT_SS,
  APP_ENG_ASSESSMENT_CANCELLED,
  APP_ENG_ASSESSMENT_COMPLETED,
  APP_ENG_CURRENT_QUESTION_INDEX,
  ASSESSMENT_AVERAGE_TIME_STATS,
  ASSESSMENT_QUESTIONNAIRES,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
  CAMERA_RECORDING_SUPPORTED,
  SCREEN_RECORDING_SUPPORTED,
  SUBMIT_APP_ASSESSMENT_ANSWERS,
  SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA,
  SUBMIT_APP_ASSESSMENT_MEDIA
} from "@/store/modules/candidates/constants";
import { generate_random_key, wait_until } from "@/utils/global";
import moment from "moment";
import {
  GET_USER_DETAILS,
  GET_COMPANY_DETAILS
} from "@/store/modules/auth/constants";
import {
  APP_ASSESSMENTS,
  SITE_DIRECTION,
  UPLOAD_FILE_CHUNK
} from "@/store/modules/common/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import AppTimer from "@/components/AppTimer.vue";
import AccessDeniedComponent from "@/components/candidate/app_assessments/AccessDeniedComponent.vue";
import AppResourseNotFound from "@/components/candidate/app_assessments/AppResourseNotFound.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import ProgressUploader from "@/components/shared/ProgressUploader.vue";
import { AssessmentRecordType } from "@/interfaces/app.interface";
import { get_random_number_between } from "@/utils/global";
import socketService from "@/services/socket.service";
export default Vue.extend({
  name: "AppEngAssessmentRoot",
  components: {
    AppTimer,
    AppEngAssessmentHeader,
    AppEngWrittenAssessment,
    AppEngV3WrittenAssessment,
    InterviewLoading,
    AccessDeniedComponent,
    AppResourseNotFound,
    ProgressUploader
  },
  data() {
    return {
      mcqs_part1_completed: false,
      assessmentTime: 0, //time spend on assessmenty
      partTime: 0, //time spend on part
      questionTime: 0, //time spend on question
      canSelect: true,
      selected_question: "",
      speaking_questions: [] as string[],
      assessment_type: "",
      stage: "",
      answeredMcqsCount: 0,
      disabled_next_question: true as boolean, // To disable next button
      options: [] as AppEnglish3McqsAnswers[],
      error: "" as string, // Global error message
      loading: false as boolean, // Global loading
      media_stopped: false as boolean,
      screen_recroding_start_time: new Date(), // For screen recording start time
      screen_recroding_end_time: new Date(), // For screen recording end time
      camera_recording_start_time: new Date(), // For camera recording start time
      camera_recording_end_time: new Date(), // For camera recording end time
      // For screen recording
      media_stream: null as MediaStream | null, // For screen recording stream
      media_enabled: false as boolean, // To check if screen recording is enabled or not
      screen_recorder: null as MediaRecorder | null, // For screen recording
      screen_recoding_chunks: [] as Blob[], // For screen recording chunks
      // For camera/audio recording
      camera_media_stream: null as MediaStream | null, // For camera stream
      camera_media_recorder: null as MediaRecorder | null, // For camera recording
      camera_media_enabled: false as boolean, // To check if camera is enabled or not
      camera_recorded_blobs: [] as Blob[], // For camera recording chunks
      transcript: "" as string, // User speech transcript
      // eslint-disable-next-line no-undef
      speech_recognition: null as typeof window.webkitSpeechRecognition | null,
      speech_mode: false as boolean, // To check if speech mode is enabled or not
      bot_speaking: true as boolean, // To check if bot is speaking or not
      speaking_history: [] as AssessmentChat[], // To store user speech history
      bot_image: require("@/assets/images/female-bot.png"),
      bot_ans_loading: false as boolean, // To check if bot is answering or not
      submit_ans_loading: false as boolean, // To check if user answer is submitting or no
      // For audio recording
      audio_recorder: null as MediaRecorder | null, // For audio recording
      audio_recorder_chunks: [] as Blob[], // For audio recording chunks
      user_ans_recorder_chunks: [] as Blob[], // For user individual answer recording chunks
      individual_ans_blob: null as Blob | null, // For individual answer blob
      // For Uploading camera/media recording files
      media_uploading: false, // To check if media uploading is in progress or not
      media_uploading_title: "", // Media uploading title
      media_uploading_progress: 0, // Media uploading progress
      ttsAudio: null as HTMLAudioElement | null, // Audio Object
      screen_recording_upload_chuk_size: 6 * 1024 * 1024,
      camera_recording_upload_chuk_size: 6 * 1024 * 1024,
      recording: false,
      startTime: 0,
      elapsedTime: "0:0",
      totalPausedTime: 0,
      paused: false,
      pauseStartTime: 0,
      audio_media_stream: null as MediaStream | null, // For camera stream,
      english_assessment_time: 1800,
      english_assessment_timer: 0,
      dialog: true,
      is_reload: false,
      disabled_mcqs1_question: true,
      timers: {} as Record<string, ReturnType<typeof setInterval>> // Store multiple timer IDs
    };
  },
  computed: {
    ViewAssessmentsOptions() {
      return ViewAssessmentsOptions;
    },
    AssessmentChatRoles() {
      return AssessmentChatRoles;
    },
    ...mapGetters("candidate", {
      app_eng_assessment: ASSESSMENT_QUESTIONNAIRES_ENGLISH,
      current_question_index: APP_ENG_CURRENT_QUESTION_INDEX,
      assessment_cancelled: APP_ENG_ASSESSMENT_CANCELLED,
      assessment_completed: APP_ENG_ASSESSMENT_COMPLETED,
      assessment_ss: APP_ASSESSMENT_SS,
      get_camera_recording_supported: CAMERA_RECORDING_SUPPORTED,
      get_screen_recording_supported: SCREEN_RECORDING_SUPPORTED,
      get_store_data: ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE
    }),
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS,
      get_company_details: GET_COMPANY_DETAILS
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  created() {
    // Random number between 8-15
    const screen_random_number = get_random_number_between(8, 15);
    const camera_random_number = get_random_number_between(8, 15);
    this.screen_recording_upload_chuk_size = screen_random_number * 1024 * 1024;
    this.camera_recording_upload_chuk_size = camera_random_number * 1024 * 1024;
    // Add the event listener when the component is created
    // document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.init_process(); // To start the Process
    this.check_stage();
    let new_ass_time =
      Number(localStorage.getItem("englishAssessmentTimer")) || 0;

    this.english_assessment_time = this.english_assessment_time - new_ass_time;

    this.startTimer("english_assessment_timer");
    this.increment_question_index(0);
  },

  methods: {
    generate_random_key,
    ...mapMutations("candidate", {
      set_app_eng_assessments: ASSESSMENT_QUESTIONNAIRES_ENGLISH,
      update_app_eng_assessment_questions:
        ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
      increment_question_index: APP_ENG_CURRENT_QUESTION_INDEX,
      set_screen_recording_supported: SCREEN_RECORDING_SUPPORTED,
      set_camera_recording_supported: CAMERA_RECORDING_SUPPORTED
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("candidate", {
      fetch_assessment_questionnaries: ASSESSMENT_QUESTIONNAIRES,
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS,
      submit_assessment_media: SUBMIT_APP_ASSESSMENT_MEDIA,
      submit_assessment_ans_media: SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA,
      set_assessment_average_time: ASSESSMENT_AVERAGE_TIME_STATS
    }),
    ...mapActions("common", {
      upload_file_chunk: UPLOAD_FILE_CHUNK
    }),
    async check_stage() {
      const stage = localStorage.getItem("stage");
      // console.log("stage: ", stage, this.app);
      if (stage && stage !== ViewAssessmentsOptions.SPEAKING) {
        this.set_app_eng_assessments({
          filter: stage,
          speaking: this.app_eng_assessment.speaking,
          mcqs: this.app_eng_assessment.mcqs,
          written: this.app_eng_assessment.written
        });
        if (stage === ViewAssessmentsOptions.MCQS) {
          const storedArray = JSON.parse(
            localStorage.getItem("answers") || "[]"
          );
          this.options = storedArray.map((item: any) =>
            item === null ? undefined : item
          );
          // console.log("this.options1:", this.options);
          // this.options = storedArray;
        }
      } else {
        localStorage.setItem("stage", this.app_eng_assessment.filter);
      }
    },
    async process_ans(
      //MCQs answers
      question_id: number,
      question_index: number,
      question_text: string,
      answer: string,
      correct_answer: string
    ) {
      this.submit_ans_loading = true;
      const current_result = answer === correct_answer ? true : false;
      this.options[question_index] = {
        question: question_text,
        question_index: question_index,
        answer: answer,
        result: current_result
      };

      const arrayFull = Array.from({
        length: this.app_eng_assessment.mcqs.length
      }).every((_, index) => this.options[index] !== undefined);
      this.answeredMcqsCount = this.options.filter(
        (item) => item !== undefined && item !== null
      ).length;
      this.disabled_mcqs1_question =
        this.answeredMcqsCount == 10 ? false : true;
      const payload: UpdateAssessmentMcqsPayload = {
        filter: ViewAssessmentsOptions.MCQS,
        answer: answer,
        question_id: question_id
      };
      // Update localStorage with the new array
      localStorage.setItem(
        "stage",
        arrayFull ? ViewAssessmentsOptions.WRITING : payload.filter
      );
      localStorage.setItem(
        "question_id",
        !arrayFull ? payload.question_id.toString() : ""
      );

      !arrayFull
        ? localStorage.setItem("answers", JSON.stringify(this.options))
        : localStorage.setItem("answer", "");

      // console.log("payload", payload);
      this.set_app_eng_assessments({
        filter: ViewAssessmentsOptions.MCQS,
        mcqs_completed: arrayFull,
        mcqs: this.app_eng_assessment.mcqs,
        written: this.app_eng_assessment.written,
        current_question_index: question_index
      });

      // const success =
      this.update_app_eng_assessment_questions(payload); // Update current question answer in store
      // console.log("Done store data: ", this.get_store_data.mcqs[0].answer);
      const mcqs_api_payload: SubmitAssessmentMcqsAnswersApiPayload = {
        answer: answer,
        question_id: question_id,
        answer_type: this.app_eng_assessment.mcqs[question_index].question_type,
        assessment_id:
          this.app_eng_assessment.mcqs[question_index].assessment_id
      };
      // console.log("API payload: ", mcqs_api_payload);
      const response = await this.submit_assessment_answers(mcqs_api_payload);

      this.set_assessment_average_time({
        answer_time: this.questionTime.toString()
      });
      this.questionTime = 0;
      this.startTimer("questionTime");
      if (!response) {
        this.set_root_error(`${this.$t("assessments.went-wrong")}`);
        this.submit_ans_loading = false;
        return;
      }

      this.submit_ans_loading = false;
      this.disabled_next_question = !arrayFull;
    },
    // startTimer(time: keyof Vue["$data"]) {
    //   setInterval(() => {
    //     (this as any)[time]++;
    //   }, 1000);
    // },
    startTimer(time: keyof Vue["$data"]) {
      // If a timer for this variable already exists, clear it first
      if (this.timers[time]) {
        clearInterval(this.timers[time]);
      }

      // Start a new interval and store the ID in the object
      this.timers[time] = setInterval(() => {
        (this as any)[time]++;
      }, 1000);
    },
    async handleBeforeUnload(event: BeforeUnloadEvent) {
      localStorage.setItem("assessmentTime", this.assessmentTime.toString());
      localStorage.setItem(
        "englishAssessmentTimer",
        this.english_assessment_timer.toString()
      );

      this.is_reload = true;
      localStorage.setItem("partTime", this.partTime.toString());
      localStorage.setItem("questionTime", this.questionTime.toString());
      localStorage.setItem("stage", this.app_eng_assessment.filter);
    },
    // async handleVisibilityChange() {
    //   // Check if the page visibility state is "hidden"
    //   if (document.visibilityState === "hidden" && !this.is_reload) {
    //     // User switched to another tab
    //     localStorage.setItem("eng_assessment_cancelled", "1"); // Mark as cancelled
    //     await this.$router.push("/candidate/dashboard");
    //   }
    // },
    // Function to intiatia whole process
    async play_questionMedia(index: string) {
      this.canSelect = false;
      const question: any = this.app_eng_assessment.speaking[index] || null;
      const question_url: any = question
        ? question.question_options.question_voice_url
        : null;
      // console.log("question: ", question);
      this.push_chat_history_obj(
        question.question_text,
        this.format_chat_date(),
        this.bot_image
      );
      await wait_until(1500);
      if (question.question_options && question_url) {
        await this.playAudio(question_url);
      }
      // console.log("Stoped");
    },
    async init_process() {
      this.loading = true;
      try {
        await this.invokeCamera(); // Ask for camera (video/audio) permission

        // If camera permission is not supported then stop the process
        if (this.error) {
          this.loading = false;
          return;
        }
        // If user didn't allow camera permission then stop the process
        if (
          !this.camera_media_stream ||
          !this.speech_recognition ||
          !this.camera_media_recorder
        ) {
          this.loading = false;
          return;
        }
        this.camera_media_enabled = true; // Set camera enabled to true

        await this.invokeGetDisplayMedia(); // Ask for screen recording permission
        // If screen recording permission not supported
        if (this.error) {
          this.loading = false;
          return;
        }
        // If user didn't allow screen recording permission then stop the process
        if (!this.media_stream) {
          this.loading = false;
          return;
        }
        this.media_enabled = true; // Set screen recording enabled to true
        this.loading = false;
        let stage = localStorage.getItem("stage");
        const assessment = this.app_assessments.find(
          (item: any) =>
            item.id === this.app_eng_assessment?.speaking[0]?.assessment_id
        );
        this.assessment_type = assessment.assessment_type;

        if (stage === null && this.assessment_type === "english_v3") {
          localStorage.setItem("stage", ViewAssessmentsOptions.MCQS);
          stage = ViewAssessmentsOptions.MCQS;
        }
        //timer procedure start
        //getprevious time of assessment
        const assessmentTime =
          Number(localStorage.getItem("assessmentTime")) || 0;
        if (assessmentTime > 0) {
          this.assessmentTime = assessmentTime;
          localStorage.removeItem("assessmentTime");
        }
        //getprevious time of part
        const partTime = Number(localStorage.getItem("partTime")) || 0;
        if (partTime > 0) {
          this.partTime = partTime;
          localStorage.removeItem("partTime");
        }

        //getprevious time of question
        const questionTime = Number(localStorage.getItem("questionTime")) || 0;
        if (questionTime > 0) {
          this.questionTime = questionTime;
          localStorage.removeItem("questionTime");
        }
        this.startTimer("assessmentTime");
        this.startTimer("partTime");
        this.startTimer("questionTime");
        //timer procedure end
        if (
          (!stage || stage === "SPEAKING") &&
          this.assessment_type === "english_v3"
        ) {
          if (!stage) {
            console.log("this.app_assessments: ", this.app_eng_assessment);
            localStorage.setItem("stage", this.app_eng_assessment.filter);
          }
          this.speaking_questions = this.app_eng_assessment.speaking.map(
            (question: any, index: number) => ({
              value: index,
              text: question.question_text
            })
          );
        } else if (
          (!stage || stage === "SPEAKING") &&
          this.assessment_type !== "english_v3"
        ) {
          // console.log("this.assessment_type: ", this.assessment_type);
          const question =
            this.app_eng_assessment.speaking[this.current_question_index];
          // console.log("question: ", question);
          this.push_chat_history_obj(
            question.question_text,
            this.format_chat_date(),
            this.bot_image
          );
          await wait_until(1000);
          if (
            question.question_options &&
            question.question_options.question_voice_url
          ) {
            await this.playAudio(question.question_options.question_voice_url);
          }
        }
      } catch (error) {
        this.error = this.$t("assessments.unable-access").toString();
      }
    },
    // Function to invoke screen recording permission from user and start recording
    async invokeGetDisplayMedia() {
      // Check if browser supports screen recording
      if (navigator?.mediaDevices?.getDisplayMedia) {
        try {
          // Ask for screen recording permission
          this.media_stream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true
          });
          if (!this.media_stream) return;
          this.screen_recorder = new MediaRecorder(this.media_stream); // Create a media recorder
          this.screen_recorder.start(15000); // Start recording
          this.screen_recroding_start_time = new Date(); // Set camera recording start time
          // ondataavailable event will be triggered when there is data available
          this.screen_recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.screen_recoding_chunks.push(event.data);
            }
            if (
              !this.assessment_cancelled &&
              !this.assessment_completed &&
              this.screen_recorder?.state !== "recording"
            ) {
              this.set_root_error(`${this.$t("assessments.share-screen")}`);
              this.invokeGetDisplayMedia();
              return;
            }
            if (
              this.screen_recoding_chunks.reduce(
                (sum, chunk) => sum + chunk.size,
                0
              ) >= this.screen_recording_upload_chuk_size
            ) {
              this.upload_recording_chunks(AssessmentRecordType.SCREEN);
            }
          };
          // onstop event will be triggered when user stops recording
          this.screen_recorder.onstop = () => {
            if (this.screen_recorder) {
              this.screen_recorder.stream
                .getTracks()
                .forEach((track) => track.stop());
              this.screen_recorder.ondataavailable = null;
              this.screen_recorder.onstop = null;
              this.screen_recorder = null;
              this.media_stream = null;
              this.screen_recroding_end_time = new Date(); // Set camera recording end time
            }
          };
          this.set_screen_recording_supported(true);
        } catch (error) {
          this.media_enabled = false;
          this.media_stream = null;
          this.screen_recorder = null;
        }
      } else {
        this.error = this.$t("assessments.browser-unsupport").toString();
        this.media_enabled = false;
        this.media_stream = null;
        this.screen_recorder = null;
        this.set_screen_recording_supported(false);
        this.loading = false;
      }
    },
    async invokeCamera() {
      if (navigator?.mediaDevices?.getUserMedia) {
        try {
          const result = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
          });
          const audio_stream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          if (result.active && audio_stream.active) {
            this.audio_media_stream = audio_stream;
            this.camera_media_stream = result;
            await this.config_speech_recognition();
            await this.config_video_recording(result);
            this.set_camera_recording_supported(true);
          }
          this.set_camera_recording_supported(true);
        } catch (e) {
          this.camera_media_stream = null;
          this.audio_media_stream = null;
          this.speech_recognition = null;
          this.camera_media_recorder = null;
          this.error = this.$t("assessments.permission-denied").toString();
        }
      } else {
        this.error = this.$t("assessments.browser-unsupport-camera").toString();
        this.camera_media_stream = null;
        this.audio_media_stream = null;
        this.speech_recognition = null;
        this.camera_media_recorder = null;
        this.loading = false;
        this.set_camera_recording_supported(false);
      }
    },
    async config_speech_recognition() {
      try {
        const speech_recognition = window.webkitSpeechRecognition;
        this.speech_recognition = new speech_recognition();
        this.speech_recognition.interimResults = true;
        this.speech_recognition.continuous = true;
        this.speech_recognition.onresult = this.handle_user_speech;
      } catch (e) {
        throw this.$t("assessments.no-speech-recognition");
      }
    },
    // eslint-disable-next-line no-undef
    handle_user_speech(result: any) {
      this.transcript = Array.from(result.results)
        // eslint-disable-next-line no-undef
        .map((result: any) => {
          if (result.isFinal) return result[0].transcript;
        })
        .join("");
    },
    async config_video_recording(stream: MediaStream) {
      try {
        this.camera_media_recorder = new MediaRecorder(stream);
        this.camera_media_recorder.start(15000);
        this.camera_recording_start_time = new Date();
        const video = this.$refs.camera as HTMLVideoElement;
        video.srcObject = stream;
        this.camera_media_recorder.ondataavailable = this.media_recorder_data;
        this.camera_media_recorder.onstop = this.handle_media_recorder_stop;
        if (this.audio_media_stream)
          this.set_audio_recorder(this.audio_media_stream);
      } catch (e) {
        throw this.$t("assessments.unable-record");
      }
    },
    handle_media_recorder_stop() {
      if (this.camera_media_recorder) {
        this.camera_media_recorder.stream
          .getTracks()
          .forEach((track) => track.stop());
        console.log("Stopping media");
        this.camera_media_recorder.ondataavailable = null;
        this.camera_media_recorder.onstop = null;
        this.camera_media_recorder = null;
        this.camera_media_stream = null;
        this.audio_media_stream = null;
        this.media_stopped = true;
        this.camera_recording_end_time = new Date();
        console.log(
          this.get_screen_recording_supported,
          this.get_camera_recording_supported,
          this.assessment_completed
        );
        if (
          this.get_screen_recording_supported &&
          this.get_camera_recording_supported &&
          this.assessment_completed
        )
          this.process_assessment_completed();
      }
    },
    async media_recorder_data(data: BlobEvent) {
      if (data.data && data.data.size > 0) {
        this.camera_recorded_blobs.push(data.data);
      }
      if (
        this.camera_recorded_blobs.reduce(
          (sum, chunk) => sum + chunk.size,
          0
        ) >= this.camera_recording_upload_chuk_size
      ) {
        this.upload_recording_chunks(AssessmentRecordType.CAMERA);
      }
    },
    speak_config() {
      const userAudioElement = this.$refs.userAudio as HTMLAudioElement;
      if (userAudioElement) {
        userAudioElement.load();
        userAudioElement.pause();
      }
      if (this.speech_mode) {
        this.speech_mode = false;
        wait_until(1000);
        this.speech_recognition.stop();
        this.audio_recorder?.pause();
        if (this.audio_media_stream) {
          const audioTrack = this.audio_media_stream.getAudioTracks()[0];
          if (audioTrack) {
            audioTrack.enabled = false;
          }
        }
        this.pauseRecording();
      } else {
        this.speech_mode = true;
        this.transcript = "";
        this.speech_recognition.start();
        if (this.audio_recorder && this.audio_recorder.state === "inactive") {
          this.start_recording();
          this.audio_recorder.start(100);
        } else {
          if (this.audio_recorder) {
            this.audio_recorder.resume();
            this.resumeRecording();
          }
        }
        if (this.audio_media_stream) {
          const audioTrack = this.audio_media_stream.getAudioTracks()[0];
          if (audioTrack) {
            audioTrack.enabled = true;
          }
        }
      }
    },
    format_chat_date(date: number = moment.now()): string {
      return moment(date).format("ddd, h:mm A").toString();
    },
    push_chat_history_obj(
      content: string,
      created_at: string,
      picture: string,
      role: AssessmentChatRoles = AssessmentChatRoles.BOT,
      id: number = generate_random_key(),
      audio_url?: string | null,
      time = ""
    ) {
      this.speaking_history.push({
        content,
        role,
        picture,
        created_at,
        id,
        audio_url,
        time
      });
    },
    async playAudio(file: string) {
      this.ttsAudio = new Audio(file);
      const video: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
      video.muted = true;
      video.loop = true;
      await video.play();
      await this.ttsAudio.play();
      // if (this.canSelect === false) {
      //   await this.clear_audio_rec();
      // }
      this.ttsAudio.onended = async () => {
        this.bot_speaking = false;
        video.pause();
        video.currentTime = 0;
        this.transcript = "";
        // this.speak_config();
      };
      // this.update(video);
    },
    // Function to get text field message based on  bot speaking
    text_field_msg() {
      if (this.bot_speaking) {
        return this.$t("candidate.interview.speak").toString();
      } else return this.$t("candidate.interview.listening").toString();
    },
    complete_mcqs_part1() {
      this.mcqs_part1_completed = true;
      console.log("mcqs_part1_completed: ", this.mcqs_part1_completed);
    },
    send_mcqs_response() {
      this.set_app_eng_assessments({
        filter: ViewAssessmentsOptions.WRITING,
        mcqs_completed: true,
        mcqs: this.app_eng_assessment.mcqs,
        written: this.app_eng_assessment.written
      });
      this.set_assessment_average_time({
        mcqs_time: this.partTime.toString()
      });
      this.questionTime = 0;
      this.partTime = 0;
      this.startTimer("partTime");
      this.startTimer("questionTime");
    },
    // Function to send user response to server and process next question
    async send_user_response() {
      this.submit_ans_loading = true;
      this.audio_recorder?.stop();
      // If user is speaking then stop speech recognition
      if (this.speech_mode) {
        this.speech_mode = false; // Set speech mode to false
        this.speech_recognition.stop(); // Stop speech recognition
        this.audio_recorder?.stop();
        this.construct_audio_url();
      }
      this.bot_speaking = true; // Set bot speaking to true
      this.bot_ans_loading = true; // Set bot answering to true
      const answer = this.transcript; // Get user answer
      this.push_chat_history_obj(
        answer,
        this.format_chat_date(),
        this.get_user.avatar_uri,
        AssessmentChatRoles.USER,
        generate_random_key(),
        this.individual_ans_blob
          ? URL.createObjectURL(this.individual_ans_blob)
          : null,
        this.elapsedTime
      );
      this.stopRecording();
      await this.update_chat_cursor(); // Update cursor of chat box
      // Update current question answer in store
      const payload: UpdateAssessmentQuestionPayload = {
        filter: this.app_eng_assessment.filter,
        answer: this.transcript,
        question_id:
          this.app_eng_assessment.speaking[this.current_question_index].id
      };
      this.update_app_eng_assessment_questions(payload); // Update current question answer in store

      localStorage.setItem(
        "stage",
        this.assessment_type === "english_v3"
          ? ViewAssessmentsOptions.MCQS
          : payload.filter
      );
      localStorage.setItem("question_id", payload.question_id.toString());
      // Api Call to save current question answer
      const api_payload: SubmitAssessmentAnswersApiPayload = {
        answer: this.transcript,
        question_id:
          this.app_eng_assessment.speaking[this.current_question_index].id,
        answer_type:
          this.app_eng_assessment.speaking[this.current_question_index]
            .question_type,
        assessment_id:
          this.app_eng_assessment.speaking[this.current_question_index]
            .assessment_id
      };
      const response = await this.submit_assessment_answers(api_payload);
      if (!response) {
        this.set_root_error(`${this.$t("assessments.went-wrong")}`);
        this.submit_ans_loading = false;
        return;
      }
      const blob = this.individual_ans_blob;
      if (this.assessment_type !== "english_v3") {
        this.increment_question_index(this.current_question_index + 1); // Increment current question
        await this.process_question(); // Process next question from bot or enable written assessment if all questions are answered;
      } else {
        const msqsCompleted =
          this.app_eng_assessment.mcqs.length < 1 ? true : false;
        const filter = msqsCompleted
          ? ViewAssessmentsOptions.WRITING
          : ViewAssessmentsOptions.MCQS;
        this.set_app_eng_assessments({
          filter: filter,
          speaking_completed: true,
          msqsCompleted: msqsCompleted,
          speaking: this.app_eng_assessment.speaking,
          mcqs: this.app_eng_assessment.mcqs,
          written: this.app_eng_assessment.written
        });
      }
      this.user_ans_recorder_chunks = []; // Reset user answer recording chunks
      const userAudioElement = this.$refs.userAudio as HTMLAudioElement;
      userAudioElement.src = ""; // Reset user audio element
      this.individual_ans_blob = null; // Reset individual answer blob
      this.submit_ans_loading = false; // Set submit answer loading to false
      const ans_id = response.id;
      if (ans_id && blob) {
        const formData = new FormData();
        formData.append(
          "audio",
          blob,
          `${this.app_eng_assessment.speaking[0].assessment_id}_ans_${ans_id}.wav`
        );
        formData.append("answer_id", ans_id.toString());
        formData.append(
          "assessment_id",
          this.app_eng_assessment.speaking[0].assessment_id
        );
        await this.submit_assessment_ans_media(formData);
      }
    },
    // Function to process next question from bot or enable written assessment
    // if all questions are answered
    // else increment current question and process next question
    async process_question() {
      // If all questions are not answered then process next question
      if (
        this.current_question_index <=
        this.app_eng_assessment.speaking.length - 1
      ) {
        // Get question
        const question =
          this.app_eng_assessment.speaking[this.current_question_index];
        // Push question in chat history
        this.push_chat_history_obj(
          question.question_text,
          this.format_chat_date(),
          this.bot_image
        );
        await this.update_chat_cursor(); // Update cursor of chat box
        this.bot_ans_loading = false; // Set bot answering to false
        this.transcript = ""; // Reset user answer
        // Play audio of question if available
        if (
          question.question_options &&
          question.question_options.question_voice_url
        ) {
          await this.playAudio(question.question_options.question_voice_url);
        }
      } else {
        // console.log("LAst question: ", this.app_eng_assessment.mcqs);
        const msqsCompleted =
          this.app_eng_assessment.mcqs.length < 1 ? true : false;
        const filter = msqsCompleted
          ? ViewAssessmentsOptions.WRITING
          : ViewAssessmentsOptions.MCQS;
        this.set_app_eng_assessments({
          filter: filter,
          speaking_completed: true,
          msqsCompleted: msqsCompleted,
          speaking: this.app_eng_assessment.speaking,
          mcqs: this.app_eng_assessment.mcqs,
          written: this.app_eng_assessment.written
        });
      }
    },
    // Function to update chat box cursor to bottom
    async update_chat_cursor() {
      await wait_until(1000); // Wait for 1 second
      const box = this.$refs.chat_box as HTMLDivElement; // Get chat box
      if (box) box.scrollTop = box.scrollHeight; // Set scroll top to scroll height
    },
    // Function to process assessment completion event
    // Upload screen recording and camera recording to server
    // Navigate to dashboard
    async process_assessment_completed() {
      let payload = {
        writing_time: this.partTime.toString(),
        english_assessment_time: this.assessmentTime.toString(),
        answer_time: this.questionTime.toString()
      };
      this.set_assessment_average_time(payload);
      if (!this.media_stopped) {
        // console.log("Media stop: ");
        if (this.speech_recognition) this.speech_recognition.stop();
        if (this.screen_recorder) this.screen_recorder.stop();
        if (this.camera_media_recorder) this.camera_media_recorder.stop();
        return;
      }
      await this.upload_recording_chunks(AssessmentRecordType.SCREEN);
      await this.upload_recording_chunks(AssessmentRecordType.CAMERA);

      localStorage.removeItem("questionTime");
      localStorage.removeItem("partTime");
      localStorage.removeItem("assessmentTime");
      await this.$router.push("/candidate/dashboard");
    },
    // Set Title When Site Direction Change
    camera_enable_title() {
      return this.$t(
        "candidate.interview.permission.declined-title"
      ).toString();
    },
    screen_enable_title() {
      return this.$t(
        "candidate.interview.permission.declined-title1"
      ).toString();
    },
    async process_reshare_screen() {
      await this.invokeGetDisplayMedia();
      if (this.media_stream) {
        this.media_enabled = true;
        this.error = "";
      }
    },
    async uploadFileWithChunks(file: File, filename: string) {
      this.media_uploading = true; // Set media uploading to true
      let retry = 0;
      const chunkSize = 2 * 1024 * 1024; // 2MB chunk size
      let start = 0;
      // Loop until start is less than file size
      while (start < file.size) {
        let end = Math.min(start + chunkSize, file.size); // Get end index
        let chunk = file.slice(start, end); // Get chunk
        const formData = new FormData(); // Create a form data
        formData.append("file", chunk, filename); // Append chunk to form data
        // formData.append("start", start.toString()); // Append start index to form data
        formData.append("filename", filename); // Append file name to form data
        const response = await this.upload_file_chunk(formData); // Api call to upload file chunk
        if (response && retry < 15) {
          start = end; // If response exist then set start to end
          retry = 0;
          this.media_uploading_progress = Math.round((start / file.size) * 100); // Set media uploading progress
        } else if (retry >= 15) {
          this.set_root_error(`${this.$t("assessments.upload-file-error")}`);
          break;
        } else {
          retry += 1;
        }
      }
      this.media_uploading = false; // Set media uploading to false
      this.media_uploading_progress = 0; // Set media uploading progress to 0
      this.media_uploading_title = ""; // Set media uploading title to empty
    },
    construct_audio_url() {
      const userAudioElement = this.$refs.userAudio as HTMLAudioElement;
      try {
        if (this.user_ans_recorder_chunks.length > 0 && userAudioElement) {
          this.individual_ans_blob = new Blob(this.user_ans_recorder_chunks, {
            type: "audio/wav"
          });
          const audioUrl = URL.createObjectURL(this.individual_ans_blob);
          userAudioElement.src = audioUrl;
          userAudioElement.load();
          // userAudioElement.play();
        }
      } catch (e) {
        // console.log(e, "e");
      }
    },
    set_audio_recorder(stream: MediaStream) {
      const audioTrack = stream.getAudioTracks()[0];
      this.audio_recorder = new MediaRecorder(new MediaStream([audioTrack]));
      this.user_ans_recorder_chunks = [];
      this.audio_recorder.ondataavailable = (event) => {
        if (audioTrack.enabled && event.data.size > 0) {
          this.user_ans_recorder_chunks.push(event.data);
          this.audio_recorder_chunks.push(event.data);
        }
      };
      this.audio_recorder.pause = () => {
        this.construct_audio_url();
      };
    },
    async clear_audio_rec() {
      // Get the parent element
      const userAudioElement = this.$refs.userAudio as HTMLAudioElement;
      if (userAudioElement) {
        this.audio_recorder?.stop();
        this.speech_recognition?.stop();
        this.audio_recorder = null;
        await wait_until(1000); // its important to wait for 1 second after clearing and audio recorder for process
        if (this.audio_media_stream) {
          this.set_audio_recorder(this.audio_media_stream); // Again intialized audio recorder
        }
        userAudioElement.src = "";
        this.individual_ans_blob = null;
        this.transcript = "";
        this.speech_mode = false;
        this.stopRecording();
      }
    },
    async upload_recording_chunks(type: AssessmentRecordType) {
      const assessment_id = this.app_eng_assessment.written[0].assessment_id; // Get assessment id
      const file_name = `assessment_${assessment_id}_user_${this.get_user.id}_${type}_recording.webm`;
      const blob_pick =
        type === AssessmentRecordType.SCREEN
          ? this.screen_recoding_chunks
          : this.camera_recorded_blobs;
      const fixedBlob = new Blob(blob_pick, {
        type: "video/webm"
      });
      // Clear blobs
      if (type === AssessmentRecordType.SCREEN) {
        this.screen_recoding_chunks = [];
      } else if (type === AssessmentRecordType.CAMERA) {
        this.camera_recorded_blobs = [];
      }
      const arrayBuffer = await fixedBlob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const bucket_prefix =
        this.get_company_details?.s3_path?.assessments_path
          .split("/")
          .slice(1)
          .join("/") ?? "open/assessments/inception";
      socketService.socket.emit("media-recorder-recording", {
        recording: buffer,
        user_id: this.get_user.id,
        file_name: file_name,
        completed: this.assessment_completed,
        cancelled: this.assessment_cancelled,
        assessment_id: assessment_id,
        assessment_type:
          this.get_company_details?.s3_path?.assessment_setting
            ?.candidates_english ?? "unknown",
        bucket_prefix,
        recording_type: type
      });
    },
    start_recording() {
      this.startTime = Date.now();
      this.recording = true;
      this.updateTime();
    },
    stopRecording() {
      if (this.recording) {
        this.recording = false;
        this.paused = false;
        this.totalPausedTime = 0;
        this.elapsedTime = "0:0";
      }
    },
    pauseRecording() {
      if (!this.paused) {
        this.paused = true;
        this.pauseStartTime = Date.now();
      }
    },
    resumeRecording() {
      if (this.paused) {
        this.totalPausedTime += Date.now() - this.pauseStartTime;
        this.paused = false;
        this.startTime += Date.now() - this.pauseStartTime;
        this.updateTime();
      }
    },
    updateTime() {
      if (this.recording && !this.paused) {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - this.startTime) / 1000);
        const minutes = Math.floor(elapsedTime / 60);
        const seconds = elapsedTime % 60;
        this.elapsedTime = minutes + ":" + seconds;
        if (this.recording) {
          setTimeout(this.updateTime, 1000); // Update time every second
        }
      }
    }
  },
  beforeDestroy() {
    // console.log("Destroying:", this.screen_recorder);
    if (this.speech_recognition) this.speech_recognition.stop(); // Stop speech recognition
    if (this.screen_recorder) this.screen_recorder.stop(); // Stop screen recording
    if (this.camera_media_recorder) this.camera_media_recorder.stop(); // Stop camera recording
    if (this.ttsAudio) this.ttsAudio.pause(); // Pause audio
    // document.removeEventListener(
    //   "visibilitychange",
    //   this.handleVisibilityChange
    // );
  }
});
