







































import Vue from "vue";
import { ViewAssessmentsOptions } from "@/interfaces/assessments";
import { mapGetters } from "vuex";
import { ASSESSMENT_QUESTIONNAIRES_ENGLISH } from "@/store/modules/candidates/constants";
export default Vue.extend({
  name: "InceptionAssessmentHeader",
  data() {
    return {
      writing_btn_icon: require("@/assets/icons/linear/edit-2.svg"),
      mcqs_btn_icon: require("@/assets/icons/linear/interactive.svg"),
      speaking_btn_icon: require("@/assets/icons/linear/airdrop.svg")
    };
  },
  props: {
    assessment_type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("candidate", {
      app_eng_assessment: ASSESSMENT_QUESTIONNAIRES_ENGLISH
    }),
    ViewAssessmentsOptions() {
      return ViewAssessmentsOptions;
    }
  }
});
